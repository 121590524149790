/**
 * Darkmode sleeve light
 * @import into styles/darkmode.scss
 * Accordions
 */

.accordion__colored-backgrounds:after,
.accordion__colored-backgrounds.accordion__no-title:before,
.accordion__colored-backgrounds .accordion__sub-title {
  border-radius: 0 !important;
}
