/**
 * Darkmode sleeve light
 * @import into styles/darkmode.scss
 * Cards
 */

.card:not(.card__hours):not(.card__list):not(.card__landscape):not(.card__portrait--no-image):not(.card__cta):not(.card__business):not(.card__meeting):not(.card__event):not(.card__votes):not(.bg--primary):not(.bg--secondary) {
  background-color: transparent !important;
}

.card.card__hours.has-image {
  background-color: transparent !important;
}

.card:not(.card__list):not(.card__landscape).accordion--mobile {
  @media (min-width: 767.98px) {
    background: transparent !important;
  }
}

.card.accordion--mobile {
  @media (min-width: 767.98px) {
    background: transparent !important;
  }
}
