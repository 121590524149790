/**
 * Darkmode sleeve light
 * @import into styles/darkmode.scss
 * Headers
 */

.header__megamenu-extended
.navbar-nav__primary-container
.navbar-nav__primary
.nav-item__search-toggle
.navbar__search-toggle:after {
  background-color: var(--darkmode-hightlight-1-200) !important;
}
