/* Dark mode - sleeve light */

/* Dark mode colors */
:root {
  --darkmode-900: #212121; //body
  --darkmode-800: #424242;
  --darkmode-700: #616161;
  --darkmode-600: #707070;
  --darkmode-500: #9e9e9e;
  --darkmode-400: #bdbdbd;
  --darkmode-300: #e0e0e0;
  --darkmode-200: #eee;
  --darkmode-100: #f5f5f5;
  --darkmode-50: #fafafa;
  --darkmode-hightlight-1-200: #aeead9;
  --darkmode-hightlight-1-100: #b5eada;
  --darkmode-hightlight-1-50: #e1f8f2;
  --darkmode-hightlight-2-200: #fed6a9;
  --darkmode-hightlight-2-100: #ffdbb7;
  --darkmode-hightlight-2-50: #fff2e2;
  --darkmode-overlay: rgba(33, 33, 33, 0.6);
  --darkmode-overlay-45deg: linear-gradient(
    45deg,
    rgba(33, 33, 33, 0.7) 0%,
    rgba(33, 33, 33, 0) 100%
  );
  --darkmode-overlay-0deg: linear-gradient(0deg, rgba(33, 33, 33, 0.7) 0%, rgba(33, 33, 33, 0) 95%);
  --darkmode-card-border: 1px solid var(--darkmode-600);
}

body.dark-theme {
  background-color: var(--darkmode-900) !important;

  // Styles
  @import 'darkmode/background-colors.scss';
  @import 'darkmode/elements-forms.scss';
  @import 'darkmode/icons-and-arrows.scss';
  @import 'darkmode/links-default.scss';
  @import 'darkmode/link-animations.scss';
  @import '../modules/buttons/darkmode.scss';
  @import 'darkmode/memberpage.scss';
  @import 'darkmode/modules.scss';
  @import 'darkmode/overlays.scss';
  @import 'darkmode/text.scss';

  // Components & elements
  @import '../modules/accordions/darkmode.scss';
  @import '../modules/anchor-links/darkmode.scss';
  @import '../modules/banners/darkmode.scss';
  @import '../modules/blockquote/darkmode.scss';
  @import '../modules/breadcrumbs/darkmode.scss';
  @import '../modules/buttons/btn__color-theme/darkmode.scss';
  @import '../modules/bypass-block/darkmode.scss';
  @import '../modules/cards/darkmode.scss';
  @import '../modules/carousels/darkmode.scss';
  @import '../modules/fact-box/darkmode.scss';
  @import '../modules/filter-list/darkmode.scss';
  @import '../modules/filter-menu/darkmode.scss';
  @import '../modules/footers/darkmode.scss';
  @import '../modules/headers/darkmode.scss';
  @import '../modules/headers/burger-menu/styles/darkmode.scss';
  @import '../modules/heros/darkmode.scss';
  @import '../modules/info-boxes/darkmode.scss';
  @import '../modules/info-boxes-article/darkmode.scss';
  @import '../modules/link-boxes/darkmode.scss';
  @import '../modules/loader/darkmode.scss';
  @import '../modules/modals/darkmode.scss';
  @import '../modules/pagination/darkmode.scss';
  @import '../modules/progressbar/darkmode.scss';
  @import '../modules/rich-text/darkmode.scss';
  @import '../modules/search-bars/darkmode.scss';
  @import '../modules/service-menu/darkmode.scss';
  @import '../modules/side-boxes/darkmode.scss';
  @import '../modules/side-menu/darkmode.scss';
  @import '../modules/tabel/darkmode.scss';
  @import '../modules/table-hours/darkmode.scss';

  @import '../modules/accordions/darkmode--sleeve-light.scss';
  @import '../modules/cards/darkmode--sleeve-light.scss';
  @import '../modules/headers/darkmode--sleeve-light.scss';
  @import '../modules/heros/darkmode--sleeve-light.scss';
  @import '../modules/media-boxes/darkmode--sleeve-light.scss';
  @import '../modules/service-menu/darkmode--sleeve-light.scss';

  // Creative
  @import '../modules/creative/aside/darkmode.scss';

  // Third-party: Components & elements
  @import '../modules/third-party/cludo-search/darkmode.scss';
  @import '../modules/third-party/medicindk/darkmode.scss';
  @import '../modules/third-party/ReadSpeaker/darkmode.scss';
  @import 'darkmode/cookie-information.scss';

  .info-box__publications,
  .events,
  .events .events__list {
    background-color: transparent !important;
  }

  .hero__article .hero__tags--over .tag {
    background-color: var(--darkmode-800);
  }

  // Start - Delete styling after login screen is changed
  .login--screen {
    .search-bar--small form .form-group input {
      color: var(--darkmode-50);
    }

    form .form-group button.login--button {
      background: var(--darkmode-hightlight-1-200);
      color: var(--darkmode-900);
    }
  }

  .article-wrapper .service-menu {
    border-bottom: 1px solid var(--darkmode-800);
  }

  .service-menu__item .rsbtn .rsbtn_play .rsbtn_left:after {
    background: none;
  }

  .login--screen form .form-group {
    border: 1px solid var(--darkmode-600);
  }
  // End - Delete styling after login screen is changed
}
