/*!
* Project: Cabana Theme
* Author: Thomas Hansen
* E-mail: tha@cabana.dk
* Website: www.cabana-theme-dk
*/

/**
* This is where all of the stylesheets are compiled.
* They are processed in the order they are imported
* to ensure the consistent increase of specificity.
*
*    ITCSS Methodology: Inverted Triangle CSS
*
*   ***************  1. Settings
*    *************   2. Themes
*     ***********    3. Tools
*      *********     4. Generic
*       *******      5. Elements
*        *****       6. Objects
*         ***        7. Components
*          *         8. Utilities
*
* 1. Settings.........Global configuration and variables.
* 2. Themes............Themes configuration and variables.
* 3. Tools............Functions and mixins.
* 4. Generic..........Ground zero styles.
*                     (normalizing.css, box-sizing etc...)
* 5. Elements.........Unclassed (bare) HTML element
*                     (like H1, Ul, A etc...)
* 6. Objects..........Common non-cosmetic structural design patterns.
*                     e.g containers, rows, grids, colums etc...
* 7. Components.......Specific cosmetic elements of UI.
*                     e.g. buttons, forms, header, footer etc...
* 8. Utilities........Helpers and overrides.
*
*    Modules..........Multi-part components.
*                     e.g. Navbar (HTML, CSS and JS).
*    Shame............All the CSS, hacks and things we are not proud of.
*/

@import "variables-sleeve/sleeve-light/_global.scss";
@import "variables-sleeve/sleeve-light/_header.scss";
@import "variables-sleeve/sleeve-light/_temporary.scss";
@import 'darkmode--sleeve-light.scss';
