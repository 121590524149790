/**
 * Darkmode
 * @import into styles/darkmode.scss
 * Headers
 */

[class*="bg-"] {  
	&.navbar .btn__color-theme {
		svg {
			stroke: var(--darkmode-hightlight-1-200) !important;
		}

		.btn__color-theme__text {
			color: var(--darkmode-50) !important;
		}
	}
}

.header__megamenu-extended,
.header__intranet {
	.navbar__megamenu-extended {
		background-color: var(--darkmode-800) !important;

		&.show-mobile-menu {
			background: var(--darkmode-600) !important;
		}

    .bg--white {
			background-color: var(--darkmode-800) !important;
		}

		[class*="bg-"] {
			background-color: var(--darkmode-700) !important;

			&.navbar-nav__secondary-container {
				.navbar-nav.navbar-nav__secondary .nav-item:not(.nav-item--cta) {
          .nav-link {
            color: var(--darkmode-50) !important;
          }
        }
    
        .nav-item:not(.nav-item--cta):not(.nav-item__social) {
          .nav-link {
            &:after {
              background: var(--darkmode-50) !important;
            }
          }
        }

        .btn__color-theme {
          background-color: var(--darkmode-600) !important;
        }
			}
		}

    .nav-item.nav-item--cta .nav-link {
      color: var(--darkmode-900) !important;
      background-color: var(--darkmode-hightlight-1-200) !important;
      border-color: var(--darkmode-hightlight-1-200) !important;

      &:hover {
        color: var(--darkmode-50) !important;
        background: transparent !important;

        [class*='icon-']::before {
          color: var(--darkmode-50) !important;
        }
      }

      [class*='icon-']::before {
        color: var(--darkmode-900) !important;
      }
    }

		.navbar__megamenu__header {
			@media only screen and (max-width: 991.98px) {
				background: var(--darkmode-800) !important;
			}

			.navbar-brand {
        h1,
        .h1 {
          color: var(--darkmode-50) !important;
  
          strong {
            color: var(--darkmode-200) !important;
          }
        }
      }
		}

		.navbar__megamenu__header__togglers {
			.navbar-toggler-icon span {
				background-color: var(--darkmode-hightlight-1-200) !important;
			}

			.navbar__search-toggle {
				&:before {
					background: var(--darkmode-700) !important;
				}

				svg path {
					fill: var(--darkmode-hightlight-1-200) !important;
				}
			}
		}

		.nav-link {
			color: var(--darkmode-50) !important;
		}

		.navbar-nav__primary {
			.nav-link {
				@media only screen and (max-width: 991.98px) {
					border-color: var(--darkmode-500) !important;
				}
			}

			.nav-item.sfHover .nav-link:after,
			.nav-link:after {
				background-color: var(--darkmode-hightlight-1-200) !important;
			}

			.nav-item__search-toggle {
				&:before {
					background-color: var(--darkmode-700) !important;
				}

				svg path {
					fill: var(--darkmode-hightlight-1-200) !important;
				}
			}

			.nav-item.nav-back-link {
				@media only screen and (max-width: 991.98px) {
					background-color: var(--darkmode-hightlight-1-200) !important;
				}

				.nav-link {
					@media only screen and (max-width: 991.98px) {
						color: var(--darkmode-900) !important;
					}

					&:before {
						border-color: var(--darkmode-900) !important;
					}
				}
			}

			.dropdown .dropdown-toggle__mobile {
				@media only screen and (max-width: 991.98px) {
					border-color: var(--darkmode-500) !important;
					background-color: var(--darkmode-700) !important;
				}

				&:after {
					@media only screen and (max-width: 991.98px) {
						border-color: var(--darkmode-hightlight-1-200) !important;
					}
				}
			}
		}

		.navbar-nav__secondary {
      .nav-item:not(.nav-item--cta):not(.nav-item__social) {
        .nav-link {
          &:after {
            background: var(--darkmode-50) !important;
          }
        }
      }
    }

		.navbar-collapse {
			@media only screen and (max-width: 991.98px) {
				background: var(--darkmode-600) !important;
			}

			.dropdown {
				.dropdown-menu {
					background-color: var(--darkmode-600) !important;

					p,
					a {
						color: var(--darkmode-50) !important;
					}

					.list__links ul li a::before {
						color: var(--darkmode-50) !important;
					}
				}

				.megamenu__extended-links {
					&:after {
						background-color: var(--darkmode-700) !important;
					}

					.list__links-image {
						@media only screen and (max-width: 991.98px) {
							background: var(--darkmode-700) !important;
						}
					}
				}

				.dropdown-menu__megamenu__links {
					@media only screen and (max-width: 991.98px) {
						background: var(--darkmode-600) !important;
					}
				}

				.dropdown-menu__megamenu {
					&.show {
						@media only screen and (max-width: 991.98px) {
							background: var(--darkmode-700) !important;
						}
					}

					.row .dropdown-submenu {
						.nav-link {
							&:before {
								@media only screen and (max-width: 991.98px) {
									border-color: var(--darkmode-hightlight-1-200) !important;
								}
							}

							&.show:after {
								@media only screen and (max-width: 991.98px) {
									background-color: var(--darkmode-700) !important;
								}
							}
						}

						.dropdown-menu li {
							border-color: var(--darkmode-500) !important;
						}
					}
				}
			}
		}
	}

	.header__megamenu-extended__search-bar {
		background-color: var(--darkmode-700) !important;

		input {
			background-color: var(--darkmode-600) !important;
		}

		button {
			color: var(--darkmode-hightlight-1-200) !important;
			border-color: var(--darkmode-hightlight-1-200) !important;
		}
	}
}

.header__intranet {
	&.has-burger-desktop {
		.navbar__megamenu-extended .navbar-collapse--desktop {
			background-color: var(--darkmode-600);

			.navbar-nav__desktop {
				border-color: var(--darkmode-500);
			}

			.dropdown .dropdown-toggle__mobile {
				border-color: var(--darkmode-500);
				background-color: var(--darkmode-700);

				&:after {
					border-color: var(--darkmode-hightlight-1-200) !important;
				}
			}

			.nav-link {
				border-color: var(--darkmode-500);
			}

			.dropdown-menu__megamenu {
				background: var(--darkmode-600);

				.nav-item.nav-back-link .nav-link {
					color: var(--darkmode-900) !important;
					background-color: var(--darkmode-hightlight-1-200);

					&:before {
						border-color: var(--darkmode-900) !important;
					}
				}

				.dropdown-menu__megamenu__links {
					background: var(--darkmode-600);
				}

				.dropdown-submenu {
					.nav-link::before {
						border-color: var(--darkmode-hightlight-1-200) !important;
					}

					.dropdown-menu li {
						border-color: var(--darkmode-500);
					}
				}
			}
		}
	}

	.container--wide, .container-fluid {
		@media only screen and (max-width: 991.98px) {
			background-color: var(--darkmode-700);
		}
	}

	.navbar__megamenu-extended {
		.navbar-profile button {
			color: var(--darkmode-50);
		}

		.navbar__megamenu__header__togglers
			.navbar-toggler
			.navbar-toggler-icon
			span,
		.navbar__megamenu__header__togglers
			.navbar-toggler--desktop
			.navbar-toggler-icon
			span {
			background-color: var(--darkmode-hightlight-1-200);
		}

		.navbar-nav__primary .nav-item__search-input .form-group {
			input {
				background-color: var(--darkmode-700);
			}

			button svg path {
				fill: var(--darkmode-hightlight-1-200);
			}
		}

		.navbar-collapse .dropdown .dropdown-menu__megamenu {
			.dropdown-menu__megamenu__links {
				@media (max-width: 991.98px) {
					background: var(--darkmode-600);
				}
			}

			.row .dropdown-submenu.show {
				background-color: var(--darkmode-700);
			}

			.megamenu__extended-links .list__links-image {
				background-color: var(--darkmode-700);
			}
		}
	}
}
