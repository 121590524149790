/*
** Sleeve Ligth
** Tempoarary styling
**
** Needs to be cleaned up:
** Either as custom styling, variables or as classes added to components
*/

.article-wrapper .rich-text .btn__selfservice {
  border-radius: 50px;
}

.accordion__colored-backgrounds.accordion__no-title:before {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.footer p,
.footer li {
	a {
		background-size: 0% 0;
		padding: 3px 0;
	}

	svg path {
		transition: all 0.2s ease-in-out;
	}

	a:hover,
	a:focus {
		background-size: 100% 100%;
		color: var(--color-footer);

		&::before {
			color: var(--color-footer-text);
		}

		svg path {
			fill: var(--color-footer);
		}
	}
}

.footer.footer__col-3-3-3-3 .footer__content.footer__content--address p {
  font-size: calc(.0625em*var(--article-subheading-font-size-lg));

  @media (max-width: 991.98px) {
    font-size: calc(.0625em*var(--article-subheading-font-size-sm));
  }
}

.info-box-article.info-box-article__contact .info-box-article__content__links {
	p {
		margin-bottom: 10px;

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

// .bg-section--primary-decorative {
// 	.card__person:not(.c-background):not(.bg--transparent) {
// 		h2,
// 		h3,
// 		p {
// 			color: var(--color-primary-decorative-text);
// 		}
// 	}
// }

// .bg-section--secondary-decorative {
// 	.card__person:not(.c-background):not(.bg--transparent) {
// 		h2,
// 		h3,
// 		p {
// 			color: var(--color-secondary-decorative-text);
// 		}
// 	}
// }

// .card__hours.has-image,
// .card__person:not(.c-background):not(.bg--transparent):not(.card__person--no-image) {
// 	background-color: transparent !important;

//   //- .card__portrait__image,
//   .card__hours__image {
//     border-top-left-radius: var(--sleeve-component-image-radius);
//     border-top-right-radius: var(--sleeve-component-image-radius);
//     border-bottom-left-radius: var(--sleeve-component-image-radius);
//     border-bottom-right-radius: var(--sleeve-component-image-radius);
// 	}

//   .card__person__image img {
//     border-top-left-radius: var(--sleeve-component-image-radius);
//     border-top-right-radius: var(--sleeve-component-image-radius);
//     border-bottom-left-radius: var(--sleeve-component-image-radius);
//     border-bottom-right-radius: var(--sleeve-component-image-radius);
// 	}

//   .card__hours__content,
// 	.card__person__text {
// 		padding: 10px 0 0 0;

// 		p {
// 			margin-bottom: 0;
// 		}
// 	}
// }

// .card__person .card__person__image,
.card__cta .card__cta__image,
.card__portrait:not(.card__portrait--no-image):not(.bg--transparent):not(.c-background) .card__portrait__image,
.card__event .card__event__image-content__image,
.card__hours.has-image .card__hours__image {
  img:not([class*="aspect-ratios--"]) {
    height: 250px;
  }
}

// .card__person:not(.c-background):not(.bg--transparent) {
//   border-radius: 0;
// }

.card:not(.card__event--date--bottom-left):not(.card__event--date--top-left):not(.card__event--date--top-right) .card__tag {
  top: 25px;
  right: 25px;
}

.card__event:not(.card__event--date--bottom-left):not(.card__event--date--top-left):not(.card__event--date--top-right) {
	.card__event__image-content {
		padding: 15px;
		margin: -15px;
		padding-bottom: 30px;

		.card__event__image-content__date {
			bottom: auto;
			top: 0;
			padding: 12px 15px;
      background-color: var(--color-btn-primary);
      border-radius: var(--sleeve-component-border-radius);

			p.day,
			p.month {
        color: var(--color-btn-primary-text);
			}
		}
	}

	.card__event__content {
		padding: 10px 30px 30px 30px;

		ul li {
			border-top: none;
			padding-top: 0;
		}
	}
}

.card.card__cta {
	.card__cta__content {
		padding-top: 30px;
	}
}

.three-column-carousel {
	.card.card__cta,
	.card__event {
		@media (max-width: 991.98px) {
			margin-top: 15px;
		}
	}
}

// .card__hours.has-image,
// .card__person:not(.c-background):not(.bg--transparent) {
// 	.arrow {
// 		display: none;
// 	}
// }

.info-box-article__documents .list__downloads ul li .list__download .list__download__tags {
	p {
		position: relative;

		&:after {
			content: '';
			position: absolute;
			top: 50%;
			right: -5px;
			transform: translate(0, -50%);
			width: 1px;
			height: 16px;
			background-color: var(--color-grey-medium);
		}

		&:last-of-type {
			&:after {
				display: none;
			}
		}
	}
}

.info-box.info-box__links__icon--large {
	padding-top: 20px;

	.info-box__links__icon--large__bg:not(.flex-left):not(.flex-center){
		justify-content: flex-end;
		margin-bottom: 10px;

		//- IE/Firefox
		flex-direction: row-reverse;
		margin-right: auto;

    @media (max-width: 991.98px) {
      margin-right: 20px;
      margin-bottom: 0;
    }
	}
}

.media-box--full-width {
	//- For WCAG - Can't be seen because of image

	@media (max-width: 991.98px) {
		min-height: 500px;
	}

	.container {
		@media (max-width: 991.98px) {
			display: flex;
			align-items: flex-end;
		}
	}
}

.title .title__icon .title__icon__bg {
  background: var(--color-primary);

  //- Icon
  &:before {
    color: var(--color-primary-text);
  }
}

.title__link{
	a {
		margin-right: 0;
	}
}

//- Go through all bullet list in fact boxes
.fact-box .fact-box__text ul li:before {
	line-height: inherit;
}

//- .breadcrumb,
// .breadcrumb.breadcrumb--divider-slash {
// 	li.breadcrumb-item {
// 		a {
// 			@media (max-width: 767.98px) {
// 				display: flex;
// 			}

// 			span {
// 				@media (max-width: 767.98px) {
// 					display: none;
// 				}
// 			}
// 		}

// 		//- Breadcrumb - mobile link back
// 		@media (max-width: 767.98px) {
// 			display: none;
// 		}

// 		&:nth-last-child(2) {
// 			@media (max-width: 767.98px) {
// 				display: unset;
// 			}
// 		}

// 	}
// }

.article-wrapper {
	.card__business {
		border: none;
	}

	.rich-text {
		p.intext-arrow-link {
			margin-bottom: 5px;
		}
	}
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
	background-color: var(--color-secondary) !important;
	color: var(--color-secondary-text) !important;
	border-radius: 50%;
	padding: 0;

	&:hover {
		background-color: var(--color-secondary);
		color: var(--color-secondary-text) !important;
	}
}

.filter-list {
  .list__cards {
    //- Card portrait -- filter - with tags
    .card__portrait:not(.card__portrait--no-image) {
      border: none;
    }
  }
}

// .bg-main--white,
// .body--white {
//   section {
//     &:not(.bg-section--primary-decorative):not(.bg-section--secondary-decorative):not(.bg--body):not(.article-wrapper) {
//       .card__hours.has-image,
//       .card__portrait:not(.card__portrait--no-image):not(.bg--transparent):not(.c-background) {
//         border: none;
//       }

//       .card__person {
//         &.card__person--no-image {
//           background-color: var(--main-white-bg-component-bg) !important; 
//         }
//       }
//     }
//   }
// }

// main:not(.bg-main--white) .bg-section--invert-bg-main {
//   .card__portrait:not([class*="bg-"]):not(.c-background) {
//     border: none;
//     background-color: unset;
//   }
// }
